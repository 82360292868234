import Swiper from 'swiper';
import 'swiper/css';

function updateSlidesPerView() {
  const screenWidth = window.innerWidth;
  let slidesToShow = 4;

  if (screenWidth < 768) {
    slidesToShow = 1;
  } else if (screenWidth < 1024) {
    slidesToShow = 2;
  } else {
    slidesToShow = 4;
  }

  return slidesToShow;
}

document.addEventListener('DOMContentLoaded', function () {
  const swiper = new Swiper('.mySwiper', {
    slidesPerView: updateSlidesPerView(),
    spaceBetween: 30,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  document
    .querySelector('.swiper-button-next')
    .addEventListener('click', function () {
      swiper.slideNext();
    });
  document
    .querySelector('.swiper-button-prev')
    .addEventListener('click', function () {
      swiper.slidePrev();
    });

  window.addEventListener('resize', () => {
    swiper.params.slidesPerView = updateSlidesPerView();
    swiper.update();
  });

  function updateNavigationImages() {
    // Обновление кнопки предыдущего слайда
    if (swiper.isBeginning) {
      document.querySelector('.swiper-button-prev.active').style.display =
        'none';
      document.querySelector('.swiper-button-prev.inactive').style.display =
        'block';
    } else {
      document.querySelector('.swiper-button-prev.active').style.display =
        'block';
      document.querySelector('.swiper-button-prev.inactive').style.display =
        'none';
    }
    // Обновление кнопки следующего слайда
    if (swiper.isEnd) {
      document.querySelector('.swiper-button-next.active').style.display =
        'none';
      document.querySelector('.swiper-button-next.inactive').style.display =
        'block';
    } else {
      document.querySelector('.swiper-button-next.active').style.display =
        'block';
      document.querySelector('.swiper-button-next.inactive').style.display =
        'none';
    }
  }

  swiper.on('init', updateNavigationImages);
  swiper.on('slideChange', updateNavigationImages);

  swiper.emit('init');
});
